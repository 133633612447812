import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { useDispatch, useSelector } from "react-redux";
import he from 'he';
import { SET_RANDOM_GALLERY_DATA } from "./store/dataReducer";
import RestClient from "./RestClient";
import { useHistory } from "react-router-dom";


const getRandomImages = ( token, dispatch, history ) => {

  const url = "https://didimari.de/space/random_images.php";
  const body = "token=" + token;

  const onSuccess = ( data ) => {
    const images = [];
    data.forEach( imageData => {
      images.push( {
        original: "https://didimari.de/images/".concat( imageData.folderName ).concat( "/" ).concat( imageData.image ),
        description: he.decode( imageData.description )
      } )
    } );
    dispatch( {
      type: SET_RANDOM_GALLERY_DATA,
      data: images,
    } );
  };

  RestClient( url, body, onSuccess, history );
}


const RandomImageGallery = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [gallery, setGallery] = useState( null );
  const [fullscreen, setFullscreen] = useState( false );
  const images = useSelector( state => state.data.randomGalleryData )
  const user = useSelector( state => state.user );


  useEffect( () => {
    if( !images || images.length === 0 ) {
      getRandomImages( user.token, dispatch, history );
    }
  }, [user.token, images, dispatch, history] );

  if( images.length === 0 ) {
    return <div className="lds-dual-ring"></div>
  }

  return <div>
    <ImageGallery
      items={images}
      showThumbnails={false}
      showPlayButton={false}
      showNav={fullscreen}
      showFullscreenButton={fullscreen}
      ref={( comp ) => setGallery( comp )}
      onScreenChange={( fullscreen ) => {
        setFullscreen( fullscreen );
      }}
      disableSwipe={!fullscreen}
      lazyLoad={true}
    />
    {fullscreen !== true && <button
      onClick={() => {
        setFullscreen( true );
        gallery.toggleFullScreen();
      }}
      className="downloadButton"
    >
      Mehr zufällige Bilder anschauen
    </button>}

  </div>;
}

export default RandomImageGallery;


