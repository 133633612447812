import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  APPEND_MORE_NEWS, GALLERY_CALLER, SET_GALLERY_CALLER, SET_OVERVIEW_DATA, SET_VIDEO_CALLER, VIDEO_CALLER
} from "./store/dataReducer";
import RandomImageGallery from "./RandomImageGallery";
import { useHistory } from "react-router-dom";
import he from 'he';
import useCheckToken from "./CheckToken";
import Header from "./Header";
import RestClient from "./RestClient";

const getOverviewData = ( token, dispatch, history ) => {

  const url = "https://didimari.de/space/overview_data.php";
  const body = "token=" + token;

  const onSuccess = ( data ) => dispatch( {
    type: SET_OVERVIEW_DATA,
    data: data,
  } );

  RestClient( url, body, onSuccess, history );
}


const getMoreNews = ( token, dispatch, offset, history ) => {

  const url = "https://didimari.de/space/more_news.php";
  const body = "token=" + token + "&offset=" + offset;

  const onSuccess = ( data ) => dispatch( {
    type: APPEND_MORE_NEWS,
    news: data.news,
  } );

  RestClient( url, body, onSuccess, history );
}


const parseNews = ( news, history, user, dispatch, offset ) => {

  return <div>
    {news.map( newsEntry => {

      let imageSrc = "https://didimari.de/app_images/gallery.png";

      return <div onClick={() => {
          dispatch( {
            type: SET_GALLERY_CALLER,
            caller: GALLERY_CALLER.OVERVIEW
          } );
          history.push( "/gallery/".concat( newsEntry.id ).concat( "/" ).concat( user.token ) );

        return null;
      }} className="newsPanel ">

        <div className="iconArea">
          <img src={imageSrc}
               alt="icon"/>
        </div>
        <div className="textArea">
          {newsEntry.creation_date}<br/>
          {he.decode( newsEntry.description )}
        </div>


      </div>
    } )}

    {false && offset < 15 && <div style={{ width: "80%" }}>
      <div className="floatRight">
        <button
          onClick={() => {
            getMoreNews( user.token, dispatch, offset, history );
          }}
          className="downloadButton"
        >
          Mehr anzeigen
        </button>
      </div>
      <div style={{ clear: "both" }}>
      </div>
    </div>}

  </div>
}


const Overview = () => {

  const [validUser, setValidUser] = useState( false );
  useCheckToken( validUser, setValidUser );

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector( state => state.user );
  const galleryCount = useSelector( state => state.data.galleryCount );
  const news = useSelector( state => state.data.news );
  const initialized = useSelector( state => state.data.initialized );
  const offset = useSelector( state => state.data.offset )
  const [parsedNews, setParsedNews] = useState( null );
  const [goToGalleries, setGoToGalleries] = useState( false );


  useEffect( () => {

    if( validUser && user && user.token !== null && !initialized ) {
      getOverviewData( user.token, dispatch, history );
    }
  }, [user, dispatch, validUser, initialized, history] );


  useEffect( () => {

    if( validUser && news && news.length > 0 ) {
      setParsedNews( parseNews( news, history, user, dispatch, offset ) );
    }
  }, [news, setParsedNews, history, user, validUser, dispatch, offset] );



  if( goToGalleries === true ) {
    setGoToGalleries( false );
    history.push( "/galleries/".concat( user.token ) );
    return null;
  }




  if( !validUser ) return null;

  return <>

    <Header user={user}/>

    <div className="center800 ">

      <div className="marginTop40 font48LightBlue  marginLeft20 ">
        Hallo {user.name}!
      </div>


      <div className="marginTop40">
        <RandomImageGallery/>
      </div>


      <div className="marginTop40 font48LightBlue marginLeft20 ">
        Viel zu entdecken:
      </div>

      <div className="statisticBox" onClick={() => {
        setGoToGalleries( true );
      }}>
        <div className="font80BoldWhite">
          {galleryCount}
        </div>
        <div className="font32BoldWhite">
          Galerien
        </div>
      </div>


      <div style={{ clear: "both" }}></div>


      <div className="marginTop40 font48LightBlue marginLeft20 ">
        Neu:
      </div>

      <div className="marginBottom80">
        {parsedNews !== null ? parsedNews : <div className="lds-dual-ring"></div>}
      </div>

    </div>
  </>
}

export default Overview




