import React from 'react';
import './App.css';
import Start from "./Start";
import Overview from "./Overview";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import store from './store/store';
import Route from "react-router-dom/es/Route";
import ScrollToTop from "./ScrollToTop";
import ImageGalleries from "./ImageGalleries";
import Gallery from "./Gallery";
import NotFoundPage from "./NotFoundPage";
import InvalidSesion from "./InvalidSession";
import Error from "./Error";
import Logout from "./Logout";


function App() {

  return (<div>
    <Provider store={store}>
      <Router basename="/">
        <>
          <ScrollToTop/>
          <Switch>
            <Route path={"/"} component={Start} exact={true}/>
            <Route path={"/invalid_session"} component={InvalidSesion} exact={true}/>
            <Route path={"/error"} component={Error} exact={true}/>
            <Route path={"/logout"} component={Logout} exact={true}/>
            <Route path={"/overview/:token"} component={Overview} exact={true}/>
            <Route path={"/galleries/:token"} component={ImageGalleries} exact={true}/>
            <Route path={"/gallery/:id/:token"} component={Gallery} exact={true}/>
            <Route component={NotFoundPage}/>
          </Switch>
        </>
      </Router>
    </Provider>
  </div>);
}

export default App;
