import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { USER_LOGIN } from "./store/userReducer";
import RestClient from "./RestClient";


const doLogin = ( user, password, loginFailed, setLoginFailed, dispatch, setGoToOverview, history ) => {

  if( loginFailed ) {
    setLoginFailed( false );
  }

  const url = "https://didimari.de/space/logmein.php";
  const body = "name=" + user + "&pass=" + password;

  const onSuccess = ( data ) => {

    if( data.error === "true" ) {
      setLoginFailed( true );
    }
    else {
      dispatch( {
        type: USER_LOGIN,
        user: data,
      } );
      setGoToOverview( true );
    }
  }

  RestClient( url, body, onSuccess, history );
}


const LoginForm = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector( state => state.user );
  const [goToOverview, setGoToOverview] = useState( false );
  const [user, setUser] = useState( "" );
  const [password, setPassword] = useState( "" );
  const [userValid, setUserValid] = useState( true );
  const [passwordValid, setPasswordValid] = useState( true );
  const [loginFailed, setLoginFailed] = useState( false );


  const checkInput = () => {
    if( user === "" ) {
      setUserValid( false );
      return false;
    }
    if( password === "" ) {
      setPasswordValid( false );
      return false;
    }
    return true;
  }

  if( goToOverview === true && userData !== null ) {
    setGoToOverview( false );
    history.push( "/overview/".concat( userData.token ) );
    return null;
  }


  return (

    <>
      Bitte melde Dich an
      <div className="marginTop40 inputField">
        {loginFailed && <div className="validationText">Der Benutzername oder das Password sind nicht korrekt.</div>}
        {!userValid && <div className="validationText">Bitte fülle dieses Feld aus</div>}
        <form onSubmit={( e ) => {
          e.preventDefault();
          e.stopPropagation();
          if( checkInput() ) {
            doLogin( user, password, loginFailed, setLoginFailed, dispatch, setGoToOverview, history );
          }
        }}>
          <input
            type="text"
            value={user}
            placeholder="Benutzername"
            required={true}
            onChange={( e ) => {
              setUserValid( true );
              setUser( e.target.value );
            }}
          />
        </form>
      </div>
      <div className="marginTop40 inputField">
        {!passwordValid && <div className="validationText">Bitte fülle dieses Feld aus</div>}
        <form onSubmit={( e ) => {
          e.preventDefault();
          e.stopPropagation();
          if( checkInput() ) {
            doLogin( user, password, loginFailed, setLoginFailed, dispatch, setGoToOverview, history );
          }
        }}>
          <input
            type="password"
            value={password}
            required={true}
            placeholder="Passwort"
            onChange={( e ) => {
              setPasswordValid( true );
              setPassword( e.target.value );
            }}
          />
        </form>
      </div>
      <div className="marginTop40 ">
        <button className="downloadButton"
                onClick={() => {
                  if( checkInput() ) {
                    doLogin( user, password, loginFailed, setLoginFailed, dispatch, setGoToOverview, history );
                  }
                }}
        >
          Anmelden
        </button>
      </div>
    </>)

}
export default LoginForm;