import React, { useEffect, useState } from 'react';
import "react-image-gallery/styles/css/image-gallery.css";
import { useDispatch, useSelector } from "react-redux";
import { GALLERY_CALLER, SET_GALLERY_CALLER, SET_IMAGE_GALLERIES } from "./store/dataReducer";
import { useHistory } from "react-router-dom";
import he from 'he';
import useCheckToken from "./CheckToken";
import Select from 'react-select'
import Header from "./Header";
import RestClient from "./RestClient";


const getImageGalleries = ( token, dispatch, history ) => {

  const url = "https://didimari.de/space/image_galleries.php";
  const body = "token=" + token;

  const onSuccess = ( data ) => dispatch( {
    type: SET_IMAGE_GALLERIES,
    data: data,
  } );

  RestClient( url, body, onSuccess, history );
}


const ImageGalleries = () => {

  const [validUser, setValidUser] = useState( false );
  useCheckToken( validUser, setValidUser );
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector( state => state.user );
  const imageGalleries = useSelector( state => state.data.imageGalleries );
  const [goToGallery, setGoToGallery] = useState( null );
  const [goToOverview, setGoToOverview] = useState( false );
  const [filter, setFilter] = useState( "" );

  const dropdownOptions = [{
    value: 'creationDateDesc',
    label: 'Neuste angelegt zuerst'
  }, {
    value: 'creationDateAsc',
    label: 'Älteste angelegt zuerst'
  }, {
    value: 'captureDateAsc',
    label: 'Neustes Aufnahmedatum zuerst'
  }, {
    value: 'captureDateDesc',
    label: 'Altestes Aufnahmedatum zuerst'
  }]
  const [selectedDropdownValue, setSelectedDropdownValue] = useState( dropdownOptions[0] );


  useEffect( () => {

    if( validUser && user && user.token !== null && (!imageGalleries || imageGalleries.length === 0) ) {
      getImageGalleries( user.token, dispatch, history );
    }
  }, [user, dispatch, imageGalleries, validUser, history] );


  if( goToGallery !== null ) {
    dispatch( {
      type: SET_GALLERY_CALLER,
      caller: GALLERY_CALLER.GALLERIES
    } );
    setGoToGallery( null );
    history.push( "/gallery/".concat( goToGallery.id ).concat( "/" ).concat( user.token ) );
    return null;
  }


  if( goToOverview === true ) {
    setGoToOverview( false );
    history.push( "/overview/".concat( user.token ) );
    return null;
  }


  if( !validUser ) return null;

  if( !imageGalleries || imageGalleries.length === 0 ) {
    return <div className="lds-dual-ring"></div>
  }

  const customStyles = {
    control: base => ({
      ...base,
      height: 42,
      minHeight: 42,
    }),
    valueContainer: base => ({
      ...base,
      height: 34,
      minHeight: 34
    }),
  };
  const filteredImageGalleries = imageGalleries.filter( gallery => gallery.description.toUpperCase().includes( filter.toUpperCase() ) );
  const orderedAndFilteredImageGalleries = filteredImageGalleries.sort( function( gallery1, gallery2 ) {

    if( selectedDropdownValue.value === "creationDateDesc" ) {
      return new Date( gallery2.creationDate ) - new Date( gallery1.creationDate );
    }
    if( selectedDropdownValue.value === "creationDateAsc" ) {
      return new Date( gallery1.creationDate ) - new Date( gallery2.creationDate );
    }
    if( selectedDropdownValue.value === "captureDateAsc" ) {
      return new Date( gallery2.captureDate ) - new Date( gallery1.captureDate );
    }
    if( selectedDropdownValue.value === "captureDateDesc" ) {
      return new Date( gallery1.captureDate ) - new Date( gallery2.captureDate );
    }
    return 0;
  } );

  return <>

    <Header user={user}/>

    <div className="font48DodgerBlue pointer marginLeft20 floatLeft"
         title="Zurück zur Startseite"
         onClick={() => setGoToOverview( true )}>&larr;
    </div>

    <div className="sortGalleries">
      <input
        type="text"
        value={filter}
        placeholder="Suche"
        onChange={( e ) => {
          setFilter( e.target.value );
        }}
      />
    </div>
    <div className="sortGalleries">
      <Select options={dropdownOptions}
              isSearchable={false}
              value={selectedDropdownValue}
              onChange={( e ) => setSelectedDropdownValue( e )}
              styles={customStyles}
      />

    </div>
    <div style={{ clear: "both" }}></div>


    <div style={{
      "margin-left": "auto",
      "margin-right": "auto",
      "width": "70%"
    }} className="marginTop20 marginBottom80">

      {orderedAndFilteredImageGalleries.map( gallery => {
        return <div onClick={() => setGoToGallery( gallery )} className="gallery-box">
          {he.decode( gallery.description )}
        </div>
      } )}
      <div style={{ clear: "both" }}></div>
    </div>
  </>
}

export default ImageGalleries;


